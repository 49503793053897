<template>
  <button
    type="button"
    class="button-crypto-pay"
    :disabled="disabledPayBtn"
    :is-processing="isLoading"
    @click="emit('onLickPay')"
  >
    {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.walletConnect.pay') }}
  </button>
</template>

<script lang="ts" setup>
  const emit = defineEmits(['onLickPay']);

  defineProps<{
    disabledPayBtn: boolean;
    isLoading: boolean;
  }>();

  const { getContent } = useProjectMethods();

  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
</script>

<style src="~/assets/styles/components/button/crypto-pay.scss" />
